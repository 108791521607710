<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import Layout from '@/components/common/Layout.vue';
import Tile from '@/components/common/Tile.vue';
import PremiumOfferModal from '@/components/offers/PremiumOfferModal.vue';
import LoyaltyOfferModal from '@/components/offers/LoyaltyOfferModal.vue';
import LoyaltyImport from '@/components/profile/LoyaltyImport.vue';
import useCurrentCompany from '@/composables/useCurrentCompany';
import companyOffersService from '@/services/companyOffers.service';
import companyOffersConstants from '@/constants/companyOffers.constants';
import useCompanyOffers from '@/composables/useCompanyOffers';
import loyaltyCardStatusConstants from '@/constants/loyaltyCardStatus.constants';
import useAuthenticatedUser from '@/composables/useAuthenticatedUser';
import useCurrentCompanyDetails from '@/composables/useCurrentCompanyDetails';
import router from '@/router';
import routesConstants from '@/constants/routes.constants';

import AddOnlineShopModal from './AddOnlineShopModal.vue';

const { t } = useI18n();
const toast = useToast();
const route = useRoute();

const { isAdmin } = useAuthenticatedUser();
const { currentCompanyId } = useCurrentCompany();
const { currentCompany } = useCurrentCompanyDetails();
const {
  offer,
  usePremium,
  loyaltyCardInfo,
  loyaltyCardStatus,
  fetchOffer,
} = useCompanyOffers();

const loading = ref(false);
const activeModal = ref(route.query.type);
const showImportModal = ref(false);

const features = computed(() => [
  {
    key: companyOffersConstants.LOYALTY_CARD,
    active: loyaltyCardStatus.value === loyaltyCardStatusConstants.IS_LOYALTY,
    warning: loyaltyCardStatus.value === loyaltyCardStatusConstants.MUST_BE_SET_UP,
  },
  {
    key: companyOffersConstants.ONLINE_SHOP,
    active: !!currentCompany.value?.heurecaCompanyId,
    onClick: currentCompany.value?.heurecaCompanyId
      ? () => router.push(routesConstants.CATALOG)
      : null,
  },
  {
    key: companyOffersConstants.PREMIUM,
    active: usePremium.value,
  },
]);

const showPremiumInfo = computed(() => activeModal.value === companyOffersConstants.PREMIUM);
const showLoyaltyCardInfo = computed(
  () => activeModal.value === companyOffersConstants.LOYALTY_CARD,
);
const showOnlineShopInfo = computed(() => activeModal.value === companyOffersConstants.ONLINE_SHOP);

const setShownInfo = (key) => {
  if (activeModal.value === key) {
    return;
  }

  activeModal.value = key;
};

const closeModal = () => {
  activeModal.value = null;
};

const openImportModal = () => {
  closeModal();
  showImportModal.value = true;
};

const updatePremiumOffer = async (newOffer) => {
  try {
    loading.value = true;
    offer.value = await companyOffersService.updateCompanyOffer(newOffer.id, newOffer);

    closeModal();
    toast.success(t('pages.offers.premiumHasBeenUpdated'));
    fetchOffer(currentCompanyId.value);
  } catch (err) {
    const [error] = err;

    toast.error(t(error));
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <Layout :loading="loading">
    <h1 class="mb-1 tac">{{ t('pages.offers.title') }}</h1>
    <p class="mb-2 tac">{{ t('pages.offers.description') }}</p>
    <div :class="$style.offerTiles">
      <Tile
        v-for="feature of features"
        :key="feature.key"
        :class="$style.offer"
        :active="feature.active"
        :warning="feature.warning"
        @click="feature.onClick?.() || setShownInfo(feature.key)"
      >
        <h2 :class="['mb-2', $style.offerTitle]">
          {{ t(`pages.offers.features.${feature.key}.title`) }}
        </h2>
        <p
          :class="$style.offerDescription"
          v-html="t(`pages.offers.features.${feature.key}.subtitle`)"
        />
      </Tile>
    </div>

    <template v-if="offer">
      <PremiumOfferModal
        :visible="showPremiumInfo"
        :offer="offer"
        @close="closeModal"
        @update="updatePremiumOffer"
      />

      <LoyaltyOfferModal
        :visible="showLoyaltyCardInfo"
        :loyaltyCard="loyaltyCardInfo"
        :loyaltyCardStatus="loyaltyCardStatus"
        :currentCompanyId="currentCompanyId"
        @close="closeModal"
        @importRequest="openImportModal"
      />

      <AddOnlineShopModal
        :companyId="currentCompanyId"
        :visible="showOnlineShopInfo"
        @close="closeModal"
      />

      <LoyaltyImport
        :companyId="currentCompanyId"
        :visible="showImportModal"
        :isAdminImport="isAdmin"
        @close="showImportModal = false"
      />
    </template>
  </Layout>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables.scss';
@import '@/assets/styles/common/mixins.scss';

.offerTiles {
  --min-width: 1fr;
  padding: 2rem;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-width), 1fr));
  place-items: stretch;

  gap: 4rem;

  @include xs {
    --min-width: 30rem;
  }

  @include lg {
    --min-width: 40rem;
  }
}

.offer {
  width: 100%;
}

.offerTitle,
.offerDescription {
  color: inherit;
}
</style>
