<script>
import { defineComponent, toRefs, ref, watch } from 'vue';

import { useI18n } from 'vue-i18n';

import Modal from '@/components/common/Modal.vue';
import Button from '@/components/common/Button.vue';
import Toggle from '@/components/common/Toggle.vue';
import InputNumberSlider from '@/components/common/InputNumberSlider.vue';

const MIN_PERCENTAGE = 5;
const MAX_PERCENTAGE = 50;

const PremiumOfferModal = defineComponent({
  components: {
    Modal,
    Button,
    Toggle,
    InputNumberSlider,
  },
  emits: ['close', 'update'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    offer: {
      type: [Object, null],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { visible, offer } = toRefs(props);

    const { t } = useI18n();

    const companyUsesPremium = ref(offer.value.usePremium);
    const catalogBasePercentage = ref(offer.value.defaultPercentValue ?? MIN_PERCENTAGE);

    watch(visible, (value) => {
      if (!value) {
        return;
      }

      if (offer.value.usePremium !== companyUsesPremium.value) {
        companyUsesPremium.value = offer.value.usePremium;
      }

      if (offer.value.defaultPercentValue !== catalogBasePercentage.value) {
        catalogBasePercentage.value = offer.value.defaultPercentValue ?? MIN_PERCENTAGE;
      }
    });

    const handleUpdate = () => {
      emit('update', {
        id: offer.value.id,
        usePremium: offer.value.usePremium ? companyUsesPremium.value : !offer.value.usePremium,
        defaultPercentValue: catalogBasePercentage.value,
      });
    };

    return {
      t,
      companyUsesPremium,
      catalogBasePercentage,
      handleUpdate,
    };
  },
  render() {
    return (
      <Modal
        isDisplayed={this.visible}
        title={this.t('pages.offers.features.premium.title')}
        onCloseModal={() => this.$emit('close')}
      >
        <p vHtml={this.t('pages.offers.features.premium.description')} />

        {this.offer.usePremium && (
          <Toggle
            class="my-1"
            vModel={this.companyUsesPremium}
            leftText={this.t('common.no')}
            rightText={this.t('common.yes')}
          />
        )}

        {(this.companyUsesPremium || !this.offer.usePremium) && (
          <>
            <p class="mt-2 mb-1">{this.t('components.premiumOfferModal.baseCatalogPercentage')}</p>
            <InputNumberSlider
              vModel={this.catalogBasePercentage}
              minValue={MIN_PERCENTAGE}
              maxValue={MAX_PERCENTAGE}
            />
          </>
        )}

        <Button small class="mt-2 tar" onButtonClick={this.handleUpdate}>
          {this.t('common.confirm')}
        </Button>
      </Modal>
    );
  },
});

export default PremiumOfferModal;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.percentageWrapper {
  display: flex;
  align-items: center;
}

.percentageNumber {
  width: calc(20% - 0.5rem);
}

.percentageSlider {
  width: calc(80% - 0.5rem) !important;
  margin-right: 2rem;
  margin-left: 0.8rem;
}
</style>
