<script setup>
import { defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';

import Modal from '@/components/common/Modal.vue';
import Button from '@/components/common/Button.vue';
import stripeAccountService from '@/services/stripeAccounts.service';
import useCurrentCompany from '@/composables/useCurrentCompany';
import routesConstants from '@/constants/routes.constants';
import apiErrorsConstants from '@/constants/apiErrors.constants';

defineProps({
  companyId: { type: String, required: true },
  visible: { type: Boolean, required: true },
});

defineEmits(['close']);

const { t } = useI18n();
const toast = useToast();
const router = useRouter();

const { currentCompanyId } = useCurrentCompany();

const handleSubscribeClick = async () => {
  try {
    const response = await stripeAccountService.getCreationUrl(currentCompanyId.value);

    if (response.redirectUrl) {
      window.location.href = response.redirectUrl;

      return;
    }

    toast.error(t('commmon.redirectionError'));
  } catch (err) {
    const [error] = err;

    if (error === apiErrorsConstants.STRIPE_ACCOUNT_HAS_ALREADY_BEEN_REGISTERED) {
      // Account is already registered
      router.push(`${routesConstants.CATALOG}?online-shop=${true}`);
    }
  }
}
</script>

<template>
  <Modal
    :isDisplayed="visible"
    :title="t('components.addOnlineShopModal.title')"
    @closeModal="$emit('close')"
  >
    <p>{{ t('pages.offers.features.onlineShop.description') }}</p>
    <Button class="tar" small @buttonClick="handleSubscribeClick">
      {{ t('components.addOnlineShopModal.subscribeButton') }}
    </Button>
  </Modal>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.buttons {
  margin-top: 2rem;
  
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include spacing-children('horizontal', 1rem);
}
</style>
