<script>
/* eslint-disable import/first */
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { defineProps, defineEmits, ref, computed, toRefs } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFile } from '@fortawesome/pro-regular-svg-icons';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';

import filesUtils from '@/utils/files.utils';

const props = defineProps({
  icon: { type: Object, required: false, default: faFile },
  allowedExtensions: { type: [Array, String], required: true },
});

const emit = defineEmits('change');

const { allowedExtensions } = toRefs(props);

const {t} = useI18n();
const toast = useToast();

const inputFileRef = ref(null);
const lastAddedFile = ref(null);
const isDropzoneActive = ref(false);

const accept = computed(() => {
  if (!Array.isArray(allowedExtensions.value)) {
    return allowedExtensions.value;
  }

  return allowedExtensions.value.join(',');
})

const handleDragover = () => {
  inputFileRef.value.value = '';
  lastAddedFile.value = null;
  isDropzoneActive.value = true;
};

const handleFileChange = (event) => {
  const [file] = event.target.files;

  if (!filesUtils.validateExtension(file, allowedExtensions.value)) {
    toast.warning(t('components.dropzone.extensionNotAllowed'));
    
    return;
  }

  lastAddedFile.value = file;
  emit('change', file);
};
</script>

<template>
  <div
    @dragover.prevent="handleDragover"
    @dragleave.prevent="isDropzoneActive = false"
    :class="[$style.fileUploaderWrapper, { [$style.active]: isDropzoneActive }]"
  >
    <input
      :class="$style.inputFileUploader"
      type="file"
      ref="inputFileRef"
      :accept="accept"
      v-bind="$attrs"
      @change="handleFileChange"
    />
    <FontAwesomeIcon :class="$style.fileIcon" :icon="icon" />

    <div class="mt-1" v-if="lastAddedFile">{{ lastAddedFile.name }}</div>

    <div :class="[$style.instructions, 'mt-2']">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.fileUploaderWrapper {
  position: relative;
  cursor: pointer;

  padding: 4rem;

  border: 5px dashed $gray;
  border-radius: 5px;

  width: fit-content;
  margin: 3rem auto 0;

  transition: border-color 0.3s;

  display: flex;
  flex-direction: column;
  align-items: center;

  &.active {
    border-color: $main-color;

    .fileIcon,
    .instructions {
      color: $main-color;
    }
  }

  .fileIcon,
  .instructions {
    transition: color 0.3s;

    color: $gray;
  }

  .fileIcon {
    font-size: 10rem;
  }
}

.inputFileUploader {
  opacity: 0;
  position: absolute;
  inset: 0;
}
</style>
