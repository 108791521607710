import config from '@/config';
import apiErrorsConstants from '@/constants/apiErrors.constants';

import httpService from '@/services/http.service';
import apiErrorUtils from '@/utils/apiErrors.utils';

const BASE_URL = config.lowcoApiUrl;

const mapError = (error) => {
  switch (error.code) {
    case apiErrorsConstants.STRIPE_ACCOUNT_HAS_ALREADY_BEEN_REGISTERED:
      return apiErrorsConstants.STRIPE_ACCOUNT_HAS_ALREADY_BEEN_REGISTERED;
    default:
      return 'errorMessages.general';
  }
};

const throwError = apiErrorUtils.makeThrowError(mapError);

const getCreationUrl = (companyId) => httpService
  .get(`${BASE_URL}/companies/${companyId}/stripe-account-url`)
  .catch(throwError);

const setConnectedId = (companyId, code) => httpService
  .put(`${BASE_URL}/companies/${companyId}/stripe-account`, { stripeCode: code })
  .catch(throwError);

export default {
  getCreationUrl,
  setConnectedId,
};
