<script setup>
import { defineProps, defineEmits, ref, toRefs, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFileCsv, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

import Modal from '@/components/common/Modal.vue';
import Button from '@/components/common/Button.vue';
import Spinner from '@/components/common/Spinner.vue';
import Dropzone from '@/components/common/Dropzone.vue';
import LoyaltyCardChoices from '@/components/offers/LoyaltyCardChoices.vue';
import companySubscriptionsService from '@/services/companySubscriptions.service';

const props = defineProps({
  isAdminImport: { type: Boolean, required: false },
  companyId: { type: String, required: true },
  visible: { type: Boolean, required: false },
});

const emit = defineEmits(['close']);

const { isAdminImport, companyId, visible } = toRefs(props);
const { t } = useI18n();
const toast = useToast();

const loading = ref(false);
const legacyCardType = ref('');
const currentFile = ref(null);
const successfullyAddedMessage = ref('');

watch(visible, (value) => {
  if (value) {
    return;
  }

  successfullyAddedMessage.value = '';
});

const importLegacyLoyalties = computed(() =>
  isAdminImport.value
    ? companySubscriptionsService.importLegacyCompanyLoyaltyByAdmin
    : companySubscriptionsService.importCompanyLegacyLoyalty,
);

const allowedFileExtensions = computed(() =>
  isAdminImport.value ? ['.csv'] : ['.csv', '.xlsx', '.xls', '.xlsm'],
);

const canSubmit = computed(() => !!currentFile.value && !!legacyCardType.value);

const handleLoyaltyImport = async (file) => {
  try {
    loading.value = true;
    const result = await importLegacyLoyalties.value(companyId.value, file, legacyCardType.value);

    if (isAdminImport.value) {
      successfullyAddedMessage.value = t('components.loyaltyImport.adminSuccessfullyAdded', [
        result.length,
      ]);

      return;
    }

    successfullyAddedMessage.value = t('components.loyaltyImport.companySuccessfullyAdded');
  } catch (err) {
    const [error] = err;

    toast.error(t(error));
  } finally {
    loading.value = false;
  }
};

const handleSubmit = () => {
  if (!currentFile.value) {
    return;
  }

  handleLoyaltyImport(currentFile.value);
};
</script>

<template>
  <Modal
    :title="t('components.loyaltyImport.title')"
    :isDisplayed="visible"
    @closeModal="emit('close')"
  >
    <p>{{ t('components.loyaltyImport.legacyCardTypeTitle') }}</p>
    <LoyaltyCardChoices class="mt-2" v-model="legacyCardType" />
  
    <p class="mb-2">
      {{ t(`components.loyaltyImport.${isAdminImport ? 'adminDescription' : 'companyDescription'}`) }}
    </p>

    <a v-if="isAdminImport" :class="$style.downloadLink" href="/fidelite_template.csv" download>
      fidelite_template.csv
    </a>

    <div v-if="loading" :class="$style.spinnerWrapper">
      <Spinner />
    </div>

    <Dropzone
      v-show="!loading && !successfullyAddedMessage"
      :allowedExtensions="allowedFileExtensions"
      :icon="faFileCsv"
      @change="currentFile = $event"
    >
      <template v-if="isAdminImport">
        {{ t('components.loyaltyImport.adminDropzoneInstructions') }}
      </template>
      <template v-if="!isAdminImport">
        {{ t('components.loyaltyImport.dropzoneInstructions') }}
      </template>
    </Dropzone>

    <div v-if="successfullyAddedMessage" :class="$style.successMessage">
      <FontAwesomeIcon :class="$style.successfullyAddedIcon" :icon="faCheckCircle" />

      <div :class="$style.successMessageDescription" v-html="successfullyAddedMessage" />

      <Button @buttonClick="emit('close')">{{ t('common.close') }}</Button>
    </div>

    <div v-if="!successfullyAddedMessage" :class="$style.buttons">
      <Button isDanger @buttonClick="emit('close')">{{ t('common.cancel') }}</Button>
      <Button :disabled="!canSubmit" @buttonClick="handleSubmit">
        {{ t('common.confirm') }}
      </Button>
    </div>
  </Modal>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.downloadLink {
  display: block;
  text-align: center;
  text-decoration: underline;
}
.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 20rem;
}

.successMessage {
  margin-top: 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  @include spacing-children('vertical', 2rem);
}

.successfullyAddedIcon {
  font-size: 10rem;
  color: $dark-green;
}

.successMessageDescription {
  text-align: center;
}
.buttons {
  margin-top: 3rem;

  display: flex;
  justify-content: flex-end;

  @include spacing-children('horizontal', 1rem);
}
</style>
