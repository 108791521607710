<template>
  <button
    :class="[
      $style.tile,
      {
        [$style.active]: active,
        [$style.warn]: !active && warning,
      },
    ]"
    @click="$emit('click')"
  >
    <div :class="$style.status">
      <FontAwesomeIcon
        v-if="active || warning"
        :class="$style.statusIcon"
        :icon="active ? faCheck : faExclamationCircle"
      />
    </div>

    <div :class="$style.content">
      <slot />
    </div>
  </button>
</template>

<script>
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

export default defineComponent({
  emits: ['click'],
  components: {
    FontAwesomeIcon,
  },
  props: {
    active: {
      type: Boolean,
      required: false,
    },
    warning: {
      type: Boolean,
      required: false,
    },
  },
  setup: () => ({
    faCheck,
    faExclamationCircle,
  }),
});
</script>

<style lang="scss" module>
@import "@/assets/styles/common/variables.scss";
@import "@/assets/styles/common/mixins.scss";

.tile {
  display: block;
  position: relative;

  border: 3px solid $gray;
  border-radius: 6px;
  padding: 1rem 2rem;

  transition: transform 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease;

  &.active {
    background-color: $dark-green;
    border-color: $dark-green;

    transform: scale(1.048);

    .status {
      background-color: $white;
    }

    .content {
      color: $white;
    }
  }

  &.warn {
    background-color: $warn;
    border-color: $warn;

    .status {
      background-color: $white;
    }
  }
}

.status {
  position: absolute;
  right: -1rem;
  top: -1rem;
  margin-right: 0;

  min-width: 3rem;
  min-height: 3rem;

  background-color: $gray;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.statusIcon {
  @keyframes spin-half {
    from {
      opacity: 0;
      transform: rotate(-180deg);
    }

    to {
      opacity: 1;
      transform: rotate(0deg);
    }
  }

  animation: spin-half 0.3s;
}
</style>
