<script>
import { defineComponent, toRefs, ref } from 'vue';

import { useI18n } from 'vue-i18n';

import Modal from '@/components/common/Modal.vue';
import Button from '@/components/common/Button.vue';
import LinkButton from '@/components/common/LinkButton.vue';
import routesConstants from '@/constants/routes.constants';
import loyaltyCardStatusConstants from '@/constants/loyaltyCardStatus.constants';

const LoyaltyOfferModal = defineComponent({
  components: {
    Modal,
    Button,
    LinkButton,
  },
  emits: ['close', 'import-request'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    loyaltyCard: {
      type: [Object, null],
      required: true,
    },
    currentCompanyId: {
      type: String,
      required: true,
    },
    loyaltyCardStatus: {
      type: [String, null],
      required: true,
    },
  },
  setup(props) {
    const { loyaltyCard } = toRefs(props);

    const { t } = useI18n();

    const loyaltyCardType = ref(loyaltyCard.value?.loyaltyCardTypeId);
    const discountValue = ref(loyaltyCard.value?.discountValue || 5);
    const valueToReach = ref(loyaltyCard.value?.valueToReach || 0);

    return {
      t,
      loyaltyCardType,
      discountValue,
      valueToReach,
    };
  },
  render() {
    return (
      <Modal
        isDisplayed={this.visible}
        title={this.t('pages.offers.features.loyaltyCard.title')}
        onCloseModal={() => this.$emit('close')}
      >
        <p vHtml={this.t('pages.offers.features.loyaltyCard.description')} />

        {this.loyaltyCardStatus === loyaltyCardStatusConstants.IS_LOYALTY && (
          <div class={[this.$style.buttons, 'mt-2']}>
            <LinkButton small to={routesConstants.SUBSCRIPTION_DETAILS}>
              {this.t('pages.offers.features.loyaltyCard.handleSubButton')}
            </LinkButton>

            {this.loyaltyCard.canImportLegacies && (
              <Button small onButtonClick={() => this.$emit('import-request')}>
                {this.t('components.loyaltyOfferModal.importButton')}
              </Button>
            )}
          </div>
        )}

        {this.loyaltyCardStatus === loyaltyCardStatusConstants.MUST_BE_SET_UP && (
          <LinkButton small warn to={routesConstants.SUBSCRIPTION_SUCCESS} class="mt-2 tac">
            {this.t('pages.offers.features.loyaltyCard.setUpLoyaltyCard')}
          </LinkButton>
        )}

        {this.loyaltyCardStatus === loyaltyCardStatusConstants.IS_FREE && (
          <LinkButton small to={routesConstants.SUBSCRIPTION_RECURRENCE_SETUP} class="mt-2 tac">
            {this.t('pages.offers.features.loyaltyCard.subscribeButton')}
          </LinkButton>
        )}
      </Modal>
    );
  },
});

export default LoyaltyOfferModal;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include spacing-children('vertical', 1rem);

  @include md {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include spacing-children('vertical', 0);
    @include spacing-children('horizontal', 1rem);
  }
}
</style>
